import React from 'react';
import { graphql } from 'gatsby';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout';
import parseMarkdown from '../utils/parseMarkdown';
import Section from '../components/Section/Section';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import { PanelGroup } from '../components/UI/Panel/Panel';
import NavList from '../components/UI/NavList/NavList';
import OfficeDetails from '../components/OfficeDetails/OfficeDetails';
import Map from '../components/Map/Map';
import { Grid, GridItem } from '../components/UI/Grid/Grid';
import Fade from 'react-reveal/Fade';
import type from '../styles/type.module.scss';
import utilities from '../styles/utilities.module.scss';

export const ContactPageTemplate = ({
    name,
    title,
    intro,
    offices,
    contact,
    isPreview
}) => {
    return (
        <>
            <Section theme={themes.LIGHT} isCompact={true}>
                <Fade bottom>
                <Hgroup lead={name} title={title} isPrimary={true}/>
                <Grid>
                    <GridItem lg="6">
                        <p><strong>{intro}</strong></p>
                    </GridItem>

                    { contact &&
                        <>
                            <GridItem md="6" lg="3">
                                <h2 className={type.h4}>Enquiries</h2>

                                <NavList items={[
                                    { label: contact.tel, link: `tel:${contact.tel.replace(/ |\(0\)/g, '')}` },
                                    { label: contact.email, link: `mailto:${contact.email}` },
                                ]} isPrimary={true}/>

                                <p className={utilities.margin0}><strong>{contact.cta}</strong></p>
                            </GridItem>

                            <GridItem md="6" lg="3">
                                <h2 className={type.h4}>Support</h2>

                                <NavList items={[
                                    { label: contact.support, link: `mailto:${contact.support}` },
                                ]} isPrimary={true}/>
                            </GridItem>
                        </>
                    }
                    
                </Grid>
                </Fade>
            </Section>

            {/* We disable the map in CMS editor due to a component error */}
            {!isPreview && 
                <Map data={ offices.map( ({lng, lat}) => ([lng, lat])) } title="Our offices."/>
            }

            <Section theme={themes.LIGHT} isUnstyled={true}>
                <PanelGroup cols={{ md: 12 / offices.length }} isFullWidth={true}>
                    {offices.map( office => {
                        return (
                            <OfficeDetails {...office} address={office.address.split(',')} key={office.title}/>
                        )
                    })}
                </PanelGroup>
            </Section>
        </>
    )
};

const ContactPage = ({ data: { page, contact } }) => {
    return (
        <Layout 
            meta={page.frontmatter.meta || false}
            title={page.frontmatter.name || false}
            theme={themes.LIGHT}
        >
            <ContactPageTemplate {...parseMarkdown(page)} contact={parseMarkdown(contact)} />
        </Layout>
    )
};

export default ContactPage;

export const pageQuery = graphql`
  ## Query for ContactPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ContactPageTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      frontmatter {
            name
            title
            intro
            offices {
                title
                address
                tel
                email
                lat
                lng
                timeZone
            }
      }
    }

    contact: markdownRemark(frontmatter: { id: { eq: "settings-contact" } }) {
        frontmatter {
          tel
          email
          support
          cta
        }
    }
  }
`
