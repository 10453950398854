import React from 'react';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';
import SnapSlider from '../../SnapSlider/SnapSlider';
import { Grid, GridItem } from '../Grid/Grid';
import Fade from 'react-reveal/Fade';
import styles from './Panel.module.scss';

const PanelGroup = ({ 
    children, 
    cols, 
    isFullWidth,
    isPrimary, 
    useSliderBelowBreakpoint, 
    breakpoint, 
    ...other 
}) => {

    const className = classNames({
        [styles.panelGroup]: !isFullWidth,
        [styles.panelGroupFullWidth]: isFullWidth,
        [styles.panelGroupPrimary]: isPrimary
    }, other.className);

    const isBreakpoint = useMediaQuery({
        query: `(max-width: ${breakpoint - 1}px)`
    });

    return useSliderBelowBreakpoint && isBreakpoint ? (
        <SnapSlider>
            {React.Children.map(children, (child) => (
                <Panel>{child}</Panel>
            ))}
        </SnapSlider>
    ) : (
        <Fade bottom>
            <div className={className}>
                <Grid className={styles.inner} {...other} hasGutters={false}>
                    {React.Children.map(children, (child) => (
                        <GridItem className={styles.panelGroupItem} {...cols}>
                            <Panel>
                                {child}
                            </Panel>
                        </GridItem>
                    ))}
                </Grid>
            </div>
        </Fade>
    )
}

PanelGroup.defaultProps = {
    isFullWidth: false,
    useSliderBelowBreakpoint: false,
    breakpoint: 1024
};

PanelGroup.propTypes = {

};

const Panel = ({ Tag, children, ...other }) => {
    const className = classNames(styles.panel, other.className);

    return (
        <Tag {...other} className={className}>
            {children}
        </Tag>
    )
}

Panel.defaultProps = {
    Tag: 'div'
}

Panel.propTypes = {

};

export { PanelGroup, Panel };
