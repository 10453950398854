import React from 'react';
import Link from '../Link/Link';
import classNames from 'classnames';
import Icon from '../Icon/Icon.js';
import isExternalLink from '../../../utils/isExternalLink.js';
import fixSlugs from '../../../utils/fixSlugs';
import styles from './ButtonLink.module.scss';

const ButtonLink = ({ children, iconName, isReverse, isPrimary, to, onClick, ...other }) => {
    const classnames = classNames( styles.buttonLink, {
        [styles.isReverse]: isReverse,
        [styles.isPrimary]: isPrimary
    }, other.className);

    if( onClick ) {
        return (
            <button {...other} className={classnames} type="button" onClick={onClick}>
                {children} <Icon className={styles.icon} name="arrow-right"/>
            </button>
        )
    } else if ( isExternalLink( to ) ) {
        return (
            <a {...other} className={classnames} href={to} target="_blank" rel="noopener noreferrer">
                {children} <Icon className={styles.icon} name="arrow-right"/>
            </a>
        )
    } else {
        return (
            <Link {...other} className={classnames} to={fixSlugs(to)}>
                {children} <Icon className={styles.icon} name="arrow-right"/>
            </Link>
        )
    }
}

export default ButtonLink;