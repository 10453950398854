import React from 'react';
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl';
import { themes } from "../../context/ThemeContext";
import Section from '../Section/Section';
import markerUrl from '../../img/marker.svg';
import styles from './Map.module.scss';

let MapBox;

if( typeof ReactMapboxGl === 'function' ) {
    MapBox = ReactMapboxGl({
        accessToken: process.env.GATSBY_MAPBOX_TOKEN,
        scrollZoom: false
    });
}

const Map = ({ data, title }) => {
    return (
        <Section theme={themes.DARK} className={styles.map} isUnstyled={true}>
            <h2 className={styles.title}>{title}</h2>

            {MapBox &&
                <>
                    { /* eslint-disable-next-line */ }
                    <MapBox style="mapbox://styles/peteluffman/cjx4relx605si1cjmm0zy150e"
                        className={styles.mapbox}
                        fitBounds={data}
                        fitBoundsOptions={{ padding: 120, pan: { animate: false } }}>
                        <ZoomControl position="bottom-right"/>

                        {data.map( ( coord, index ) => (
                            <Marker
                                coordinates={coord}
                                anchor="center"
                                key={index}>
                                <img src={markerUrl} alt=""/>
                            </Marker>
                        ))}
                        
                    </MapBox>
                </>
            }
            
        </Section>
    )
}

Map.propTypes = {

};

export default Map;
