import React from 'react';
import classNames from 'classnames';
import styles from './SnapSlider.module.scss';

const SnapSlider = ({ children, ...other }) => {
    const className = classNames(styles.snapSlider, other.className);

    return (
        <div className={className}>
            <ul className={styles.itemContainer}>
                {React.Children.map(children, (child) => (
                     <li className={styles.item} key={child.id}>
                        {child}
                     </li>
                ))}
            </ul>
        </div>
    )
}

SnapSlider.propTypes = {

};

export default SnapSlider;
