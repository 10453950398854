import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'
import Clock from 'react-clock/dist/entry.nostyle';
import ButtonLink from '../UI/ButtonLink/ButtonLink';
import NavList from '../UI/NavList/NavList';
import styles from './OfficeDetails.module.scss';

function getDateWithTimeZone( timeZone ){
    return new Date().toLocaleString("en-US", { timeZone }).replace(/[^ -~]/g,'');
}

const OfficeDetails = ({ title, address, tel, email, lat, lng, timeZone}) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1024px)'
    });

    const dateTime = getDateWithTimeZone( timeZone );
    const [date, setDate] = useState(new Date( dateTime ));

    useEffect( () => {
        const interval = setInterval( () => {
            const dateTime = getDateWithTimeZone( timeZone );
            setDate( new Date( dateTime ) )
        }, 1000 );

        return () => {
            clearInterval( interval );
        }
    }, [timeZone]);

    return (
        <div className={styles.office}>
            <div className={styles.body}>
                <Clock 
                    className={styles.clock} 
                    value={date} 
                    size={isDesktopOrLaptop ? 180 : 120} 
                    hourHandWidth={6}
                    minuteHandWidth={6}
                    secondHandWidth={2}
                    renderHourMarks={false} 
                    renderMinuteMarks={false}/>

                <h3 className={styles.title}>{title}</h3>
                <address className={styles.address}>
                    {address.map((lineItem, index) => (
                        <div key={index}>
                            {index === 0 ? <strong>{lineItem}</strong> : lineItem}
                            {index < address.length - 1 && <br/>}
                        </div>
                    ))}
                </address>

                <NavList items={[
                    { label: tel, link: `tel:${tel.replace(/ |\(0\)/g, '')}` },
                    { label: email, link: `mailto:${email}` },
                ]}/>
            </div>

            {lat && lng &&
                <ButtonLink className={styles.link} to={`https://www.google.co.uk/maps/dir//${lat},${lng}/@${lat},${lng},17z?hl=en`}>Get directions</ButtonLink>
            }
        </div>
    )
}

OfficeDetails.propTypes = {

};

export default OfficeDetails;
